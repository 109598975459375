import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UiComponent } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class UiComponentService extends ResourceService<UiComponent> {

    private externalIconUrlPrefix = "https://tools.servitly.com/configuration/ui/images/";

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'uiComponents';
    }

    getTypeIcon(type: string): string {
        return this.externalIconUrlPrefix + type?.replace(' ', '') + ".png"
    }


}