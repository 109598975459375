import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { UiComponent } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ComponentService extends ResourceService<UiComponent> {

    static CATEGORY_ICON_MAP: { [name: string]: string } = { 'FILTER': 'change_history', 'CONTROL': 'settings', 'WIDGET': 'widgets', 'TEMPLATE': 'space_dashboard', 'THING_DEFINITION': 'token', 'EXTENSION': 'cable' };

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'components';
    }

    override getRecursivelyAll(): Promise<UiComponent[]> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get<UiComponent[]>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    saveComponent(body: any, componentId?: string, version?: string, extraParams?: HttpParams): Promise<UiComponent> {
        let params = this.getContextParams(extraParams);
        if (componentId) {
            params = params.set('version', version).set('componentId', componentId);
            return firstValueFrom(this.httpClient.put<UiComponent>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
        } else {
            return firstValueFrom(this.httpClient.post<UiComponent>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
        }
    }

    override getById(componentId: string, extraParams?: HttpParams): Promise<UiComponent> {
        let params = this.getContextParams(extraParams).set('componentId', componentId);
        return firstValueFrom(this.httpClient.get<UiComponent>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    getResource(component: UiComponent, url: string): Promise<string> {
        return this.getRawResource(component, url).then(resp => resp.text());
    }

    getRawResource(component: UiComponent, url: string): Promise<Blob> {
        let params = this.getResourceParams(component, url);
        return firstValueFrom(this.httpClient.get(this.getResourceEndpoint(), { params: params, observe: "response", responseType: "blob" }))
            .then(resp => resp.body);
    }

    saveResource(component: UiComponent, url: string, content: File): Promise<Blob> {
        const formData = new FormData();
        formData.append('file', content);
        let params = this.getResourceParams(component, url);
        return firstValueFrom(this.httpClient.post<Blob>(this.getResourceEndpoint(), formData, { params: params }));
    }

    deleteResource(component: UiComponent, url: string): Promise<void> {
        let params = this.getResourceParams(component, url);
        return firstValueFrom(this.httpClient.delete<void>(this.getResourceEndpoint(), { params: params }));
    }

    private getResourceParams(component: UiComponent, url: string) {
        return this.getContextParams().set('version', component.version).set('componentId', component.componentId).set('url', url);
    }

    private getResourceEndpoint(): string {
        return `${AppConstants.API_BASE_URL}componentResources`;
    }

}