import { Thing } from "./thing";

export interface VirtualThing {
    id: string;
    name: string;
    description: string;
    separator: string;
    looping: boolean;
    thingId: string;
    defaultMetricPath: string;
    state: VirtualThingState;
    startTimestamp: number;
    startedBy: string;
    filePresent: boolean;
    sourceType: VirtualThingSourceType;
    sourceEnvironmentHostName: string;
    sourceThingId: string;
    error: string;
    // enriched
    thing: Thing;
}

export enum VirtualThingState {
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    STOPPED = 'STOPPED',
    ERROR = 'ERROR'
}

export class VirtualThingCsvValidationResponse {
    invalidMetricMappingNames: string[];
}

export enum VirtualThingSourceType {
    CSV = 'CSV',
    THING_DATA_MIRRORING = 'THING_DATA_MIRRORING'
}