import { AlgorithmComputationPeriod, AlgorithmDatasetRange } from "./algorithm";
import { AlgorithmBinding } from "./algorithm-binding";
import { BuiltInTransformerType } from "./configuration-parameter";
import { Predicate } from "./predicate";
import { ThingDefinition } from "./thing-definition";
import { Transformer } from "./transformer";

export interface Metric {
    id: string;
    name: string;
    label: string;
    unit: string;
    valueType: MetricValueType;
    thingDefinition: ThingDefinition;
    mapping: MetricMapping;
    functionInputs: boolean;
    type: MetricType;
    recordItems: RecordItem[];
    metric: Metric;
    counter: MetricCounterType;
    counterConditionProperty: RecordItem;
    counterConditionPredicate: Predicate;
    counterConditionValue: string;
    counterProperty: RecordItem;
    samplingPeriod: MetricSamplingPeriod;
    samplingReset: boolean;
    resetPeriod: MetricSamplingPeriod;
    sourceMetrics: Metric[];
    sourceProperties: string[];
    sourceFields: string[];
    sourceMappingVariables: { [key: string]: string };
    expression: string;
    derivedCases: DerivedCase[];
    deltaProperty: RecordItem;
    counterPositiveOrZero: boolean;
    min: number;
    minMetricId: string;
    minCustomerPropertyDefinitionId: string;
    minLocationPropertyDefinitionId: string;
    minThingPropertyDefinitionId: string;
    minThingDefinitionPropertyDefinitionId: string;
    max: number;
    maxMetricId: string;
    maxCustomerPropertyDefinitionId: string;
    maxLocationPropertyDefinitionId: string;
    maxThingPropertyDefinitionId: string;
    maxThingDefinitionPropertyDefinitionId: string;
    ranges: ValueRange[];
    periodicCalculation: boolean;
    resetConditionMetric: Metric;
    resetConditionPredicate: Predicate;
    resetConditionValue: string;
    resetValue: string;
    deltaPositiveOrZero: boolean;
    dataSource: MetricDataSourceType;
    outOfRangeValuesDiscarded: boolean;
    algorithmsComputationPeriod: MetricComputationPeriod;
    algorithmsDatasetRange: ThingDefinitionMetricDatasetRange;
    inputMetrics: InputThingDefinitionMetric[];
    manualResetEnabled: boolean;
    initialValue: string;
    group: string;
    description: string;
    sourcePreviousValue: boolean;
    editableResetValue: boolean;
    inputVariables: InputVariable[];
    algorithmsInsightId: string;
    algorithmsInsightOutput: string;
    countableType: CountableType;
    dictionary: DictionaryItem[];
    privatizable: boolean;
    computationPolicy: MetricComputationPolicy;
    algorithmsRuntime: AlgorithmsRuntime;
    algorithmBinding: MetricAlgorithmBinding;
    standardUsage: number;
    tolerance: number;
    usageUnit: string;
    wornPart: string;
    consecutiveEqualValuesDiscarded: boolean;
}

export enum MetricType {
    SIMPLE = "SIMPLE",
    RECORD = "RECORD",
    DERIVED = "DERIVED",
    COUNTER = "COUNTER",
    BLOB = "BLOB",
    LEGACY = "LEGACY",
    VOLATILE = "VOLATILE",
    ALGORITHM = "ALGORITHM",
    WEAR = "WEAR"
}

export interface MetricMapping {
    path: string;
    name: string;
    contentName: string;
    fileName: string;
    valueExpression: string;
    timestampExpression: string;
    valueTransformer: Transformer;
    valueBuiltInTransformer: BuiltInTransformerType;
    connectionStatusCheckTimeout: number;
    connectionStatusCheckTimeUnit: TimeoutTimeUnit;
    connectionStatusLwtPathPrefix: string;
    connectionStatusLwtBirthPath: string;
    connectionStatusLwtPath: string;
    valueEncoding: MetricValueEncoding;
    binaryPosition: number;
    binaryLength: number;
    binaryByteOrder: string;
    influxDbBucket: string;
    influxDbField: string;
    influxDbMeasurement: string;
    snowflakeQuery: string;
    connectionStatusIgnoredPaths: string[];
    connectionStatusLwtEnabled: boolean;
}

export enum MetricValueType {
    BOOLEAN = "BOOLEAN",
    DOUBLE = "DOUBLE",
    FLOAT = "FLOAT",
    INTEGER = "INTEGER",
    LONG = "LONG",
    STRING = "STRING"
}

export class RecordItem {
    mappingName: string;
    valueType: string;
    valueTransformer: Transformer;
    valueBuiltInTransformer: BuiltInTransformerType;
}

export const PREDICATE_MAPPING: { [name: string]: string } = {
    'EQUAL': '=',
    'NOT_EQUAL': '!=',
    'LESS': '<',
    'GREATER': '>',
    'LESS_EQUAL': '<=',
    'GREATER_EQUAL': '>='
}

export enum MetricCounterType {
    COUNT = 'COUNT',
    SUM_VALUES = 'SUM_VALUES',
    SUM_TIME_INTERVALS = 'SUM_TIME_INTERVALS'
}

export interface DerivedCase {
    condition: string;
    expression: string;
}

export interface ValueRange {
    to: number;
    severity: ValueRangeSeverity;
    toMetricId?: string;
    toCustomerPropertyDefinitionId?: string;
    toLocationPropertyDefinitionId?: string;
    toThingPropertyDefinitionId?: string;
    toThingDefinitionPropertyDefinitionId?: string;
    label?: string;
    icon?: string;
}

export interface InputThingDefinitionMetric {
    metricId: string;
    variable: string;
}

export interface InputVariable {
    value: string;
    variable: string;
}

export enum ValueRangeSeverity {
    NEUTRAL = 'NEUTRAL',
    NORMAL = 'NORMAL',
    WARNING = 'WARNING',
    CRITICAL = 'CRITICAL'
}

export enum SourceMappingPrefix {
    METRIC = "metric",
    RECORD = "record",
    THING = "thing",
    THING_DEFINITION = "thingDefinition",
    CUSTOMER = "customer",
    LOCATION = "location",
    THING_FIELD = "thingField"
}

export enum MetricDataSourceType {
    INFLUX_DB = 'INFLUX_DB',
    SNOWFLAKE = 'SNOWFLAKE'
}

export enum MetricComputationPeriod {
    HOURS_1 = 'HOURS_1',
    DAYS_1 = 'DAYS_1',
    WEEKS_1 = 'WEEKS_1',
    MONTHS_1 = 'MONTHS_1',
    YEARS_1 = 'YEARS_1'
}

export enum ThingDefinitionMetricDatasetRange {
    LAST_VALUE = "LAST_VALUE",
    LAST_5_MINUTES = "LAST_5_MINUTES",
    LAST_HOUR = "LAST_HOUR",
    LAST_24_HOURS = "LAST_24_HOURS",
    LAST_30_DAYS = "LAST_30_DAYS",
    THIS_WEEK = "THIS_WEEK",
    THIS_MONTH = "THIS_MONTH",
    LAST_100_VALUES = "LAST_100_VALUES",
    LAST_500_VALUES = "LAST_500_VALUES",
    LAST_1000_VALUES = "LAST_1000_VALUES"
}

export enum MetricSamplingPeriod {
    HOURS_1 = 'HOURS_1',
    DAYS_1 = 'DAYS_1',
    WEEKS_1 = 'WEEKS_1',
    MONTHS_1 = 'MONTHS_1',
    YEARS_1 = 'YEARS_1'
}

export enum MetricValueEncoding {
    LITERAL = 'LITERAL',
    BINARY_INT = 'BINARY_INT',
    BINARY_HEX = 'BINARY_HEX',
    LITERAL_JSON = 'LITERAL_JSON'
}

export enum MetricByteOrder {
    BIG_ENDIAN = 'BIG_ENDIAN',
    LITTLE_ENDIAN = 'LITTLE_ENDIAN'
}

export enum TimeoutTimeUnit {
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    DAYS = 'DAYS'
}

export enum CountableType {
    CONTINUOUS = 'CONTINUOUS',
    DISCRETE = 'DISCRETE'
}

export interface DictionaryItem {
    value: any;
    label: string;
    severity: ValueRangeSeverity;
    icon: string
}

export enum MetricComputationPolicy {
    CONTINUOUS = 'CONTINUOUS',
    SAMPLED = 'SAMPLED'
}

export enum AlgorithmsRuntime {
    PYTHON_3_8 = 'PYTHON_3_8',
    PYTHON_3_11 = 'PYTHON_3_11'
}

export interface MetricAlgorithmBinding extends AlgorithmBinding {
    output: string;
    datasetRange: AlgorithmDatasetRange;
    computationPeriod: AlgorithmComputationPeriod;
}