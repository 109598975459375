import { Company } from "./company";
import { Project } from "./project";

export interface Environment {
    id: string;
    name: string;
    companyId: string;
    company: Company;
    creationDate: number;
    createdBy: string;
    configurationStatus: string;
    hostName: string;
    cloudProvider: CloudProvider;
    region: Region;
    type: EnvironmentType;
    projectId: string;
    project: Project;
}

export enum CloudProvider {
    AWS = 'AWS',
    AZURE = 'AZURE'
}

export enum Region {
    EU = 'EU',
    APAC = 'APAC'
}

export enum EnvironmentType {
    PRODUCTION = 'PRODUCTION',
    STAGING = 'STAGING',
    DEVELOP = 'DEVELOP',
    SANDBOX = 'SANDBOX',
    TEST = 'TEST',
    TEST2 = 'TEST2',
    TEST3 = 'TEST3'
}

export interface EnvironmentCreationConfigurationSet {
    suggestedName: string;
    associatedNames: string[];
    allowedConfigurations: EnvironmentCreationConfiguration[];
}

export interface EnvironmentCreationConfiguration {
    name: string;
    type: EnvironmentType;
    cloudProvider: CloudProvider;
    region: Region;
    locked: boolean;
}