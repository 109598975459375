import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { firstValueFrom } from "rxjs";
import { PagedList } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";

@Injectable()
export abstract class ResourceService<T> {

    constructor(
        protected httpClient: HttpClient,
        protected contextService: ContextService
    ) { }

    getPagedList(page: number, pageSize: number, sort: Sort[], extraParams?: HttpParams): Promise<PagedList<T>> {
        let params = this.getContextParams(extraParams).set('page', page).set('size', pageSize);
        sort.forEach(s => params = params.append('sort', s.active + ',' + s.direction));
        return firstValueFrom(this.httpClient.get<PagedList<T>>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    protected getContextParams(extraParams?: HttpParams): HttpParams { // overridable if any paged list can be invoked inside a specific context
        let params = extraParams ?? new HttpParams();
        let environment = this.contextService.getEnvironment();
        if (environment) {
            params = params.set('environmentId', environment.id);
            let thingDefinition = this.contextService.getThingDefinition();
            if (!this.contextService.isGeneralContext()) {
                params = params.set('thingDefinitionId', thingDefinition?.id); // force even null param
            }
        } else {
            let adminGroup = this.contextService.getAdminGroup();
            if (adminGroup && !params.has('companyId') && !params.has('expertPartnerId')) {  // if company or partner is forced do not add adminGroupId
                params = params.set('adminGroupId', adminGroup.id);
            }
            let expertPartner = this.contextService.getExpertPartner();
            if (expertPartner && !params.has('companyId')) { // if company is forced do not add expertPartnerId
                params = params.set('expertPartnerId', expertPartner.id);
            }
            let company = this.contextService.getCompany();
            if (company) {
                params = params.set('companyId', company.id);
            }
        }
        if (this.contextService.isDomainContext()) {
            const domain = this.contextService.getDomain();
            if (domain && !domain.default) {
                params = params.set('domainId', domain.id);
            }
        }
        return params;
    }

    getById(id: string, extraParams?: HttpParams): Promise<T> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.get<T>(AppConstants.API_BASE_URL + this.getEndpointById(id), { params: params }));
    }

    getRecursivelyAll(extraParams?: HttpParams, sort: Sort[] = [{ active: 'name', direction: 'asc' }], page: number = 0, resources: T[] = []): Promise<T[]> {
        return this.getPagedList(page, 100, sort, extraParams).then(pagedMetrics => {
            resources = resources.concat(pagedMetrics.content);
            if (pagedMetrics.last) {
                return resources;
            } else {
                return this.getRecursivelyAll(extraParams, sort, ++page, resources);
            }
        });
    }

    save(body: any, id?: string, extraParams?: HttpParams): Promise<T> {
        let params = this.getContextParams(extraParams);
        if (id) {
            return firstValueFrom(this.httpClient.put<T>(AppConstants.API_BASE_URL + this.getEndpointById(id), body, { params: params }));
        } else {
            return firstValueFrom(this.httpClient.post<T>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: params }));
        }
    }

    delete(id: string, extraParams?: HttpParams): Promise<void> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.delete<void>(AppConstants.API_BASE_URL + this.getEndpointById(id), { params: params }));
    }

    protected abstract getEndpoint(): string;

    protected getEndpointById(id: string): string {
        return this.getEndpoint() + '/' + id;
    }
}